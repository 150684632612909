import React from "react";

export const Home = () => {
    return (
        <React.Fragment>
            <h1>Home</h1>
            <p>
                Welkom, mijn naam is Tessa en ik ben in Augustus 2003 voor mezelf begonnen als thuiskapster in Amstelveen en omgeving.
                Ik sta ingeschreven bij de kamer van koophandel.
                Ik heb de kappersopleiding gevolgd en ben in het bezit van een allround kappersdiploma. Voordat ik voor mezelf ben begonnen heb ik
                werkervaring opgedaan in verschillende kapsalons.
            </p>
            <p>Op een gegeven moment heb ik besloten om een nieuwe uitdaging aan te gaan en ben toen met groot succes voor mezelf begonnen.</p>
            <p>Wilt u meer informatie over mijn bedrijf of wilt u meer weten over de voordelen van de kapster bij u aan huis kijk dan bij informatie of surf gewoon eens lekker rond op mijn website.</p>
        </React.Fragment>
    );
}