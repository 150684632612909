import React from 'react';
import { BrowserRouter as Router, Link, Route } from "react-router-dom";
import './App.css';
import auto from './assets/images/auto.jpg';
import block from './assets/images/block.gif';
import logo from './assets/images/logo.jpg';
import { Contact } from './pages/Contact';
import { Deals } from './pages/Deals';
import { Extensions } from './pages/Extensions';
import { Home } from './pages/Home';
import { Information } from './pages/Information';
import { PriceList } from './pages/PriceList';
import { Products } from './pages/Products';
import { WorkTimes } from './pages/WorkTimes';

export const App = () => {
  return (
    <Router>
      <div id="container">
        <div id="header">
          <img src={logo} />
          <img src={auto} />
        </div>
        <div id="menu">
          <div id="menuheader">
            <div id="menutitle">Menu</div>
          </div>
          <Link className="menuitem" to="/"><img className="block" src={block} />Home</Link>
          <Link className="menuitem" to="/informatie"><img className="block" src={block} />Informatie</Link>
          <Link className="menuitem" to="/werktijden"><img className="block" src={block} />Werktijden & gebied</Link>
          <Link className="menuitem" to="/prijslijst"><img className="block" src={block} />Prijslijst</Link>
          <Link className="menuitem" to="/producten"><img className="block" src={block} />Producten</Link>
          <Link className="menuitem" to="/extensions"><img className="block" src={block} />Extensions</Link>
          <Link className="menuitem" to="aanbiedingen"><img className="block" src={block} />Aanbiedingen</Link>
          <Link className="menuitem" to="contact"><img className="block" src={block} />Contact</Link>
        </div>
        <div id="content">  
          <Route path="/" exact={true} component={Home}></Route>
          <Route path="/informatie" component={Information}></Route>
          <Route path="/werktijden" component={WorkTimes}></Route>
          <Route path="/prijslijst" component={PriceList}></Route>
          <Route path="/producten" component={Products}></Route>
          <Route path="/extensions" component={Extensions}></Route>
          <Route path="/aanbiedingen" component={Deals}></Route>
          <Route path="/contact" component={Contact}></Route>
        </div>
      </div>
    </Router>
  );
}

export default App;
