import React from "react";

export const WorkTimes = () => {
    return (
        <React.Fragment>
            <h1>Werktijden & gebied</h1>
            <b>Werktijden Amstelveen</b><br /><br />
            <table cellPadding="0" cellSpacing="0">
                <tr><td width="200">Maandag t/m Vrijdag</td><td>9:00 tot 15:00</td></tr>
                <tr><td width="200" colSpan={2}>Zaterdag en koopavond in overleg</td></tr>
            </table>
            <p>
                Het wijzigen of annuleren van een afspraak dient tenminste 24 uur van te voren te gebeuren anders<br />zijn we genoodzaakt de kosten in rekening te brengen.
            </p>
            <b>Werkgebied</b><br /><br />
            Amstelveen<br />
            Ouderkerk a/d Amstel
        </React.Fragment>
    );
}