import React from "react";

export const Information = () => {
    return (
        <React.Fragment>
            <h1>Informatie</h1>
            <p>
                De thuiskapster werkt hetzelfde als een kapsalon, alleen dan bij u thuis. Daardoor heeft u een aantal voordelen, u hoeft niet
                langer door weer en wind de deur uit en u heeft geen wachtende voor u. Daarnaast zit u gewoon lekker in een vertrouwde omgeving.
            </p>
            <p>
                De thuiskapster brengt alle benodigde spullen mee, u hoeft alleen voor een stoel te zorgen. Als u uw haar de avond of ochtend voor
                de afspraak al gewassen heeft is het niet nodig om nogmaals uw haren te wassen, tenzij u dat op prijs stelt.
            </p>
            <p>
                Alle behandelingen die in de kapsalon gedaan worden kunnen ook bij u thuis, zoals kleuren, permanenten extensions etc. De kleur of
                permanent kan gewoon bij de wastafel in de keuken of badkamer uitgespoeld worden.
            </p>
            <p>
                Easy Home Hairstyling werkt alleen met gediplomeerde haarstylisten en ook de producten zijn met zorg uitgekozen. Toch kan het gebeuren
                dat u niet helemaal tevreden bent met het resultaat. Bel dan gerust op want dan kunnen we vast nog iets betekenen.
            </p>
            <p>
                Als u nog vragen heeft neem dan contact met ons op door te bellen naar 06-53943501, of stuur een email naar <a href="mailto:tessa@easyhomehairstyling.nl">tessa@easyhomehairstyling.nl</a>
            </p>
        </React.Fragment>
    );
}