import React from "react";

export const Products = () => {
    return (
        <React.Fragment>
            <h1>Producten</h1>
            <p>
                Al onze producten waar we mee werken zijn van L'or&eacute;al Professionnel.
            </p>
            <b>Kleuring</b>
            <p>
                Wij maken gebruik van het verfproduct Majirel van Loreal. Majirel is een oxidatie cr&egrave;me kleuring die 100% grijs haar dekt, en is een egale en volle kleur met een lange houdbaarheid. Er is een keuze uit meer dan 60 tinten voor een elegante kleur.
            </p>
            <b>Permanent</b>
            <p>
                De vloeistoffen die we gebruiken zijn van Lor&eacute;al Interphase-C, dat is de meest verzorgende en herstellende omvorming. Het geeft een
                lang houdbare krul en volume, en het haar voelt natuurlijk aan.
            </p>
            <b>Verzorging</b>
            <p>Binnenkort komt hier meer informatie.</p>
            <b>Styling</b>
            <p>Binnenkort komt hier meer informatie.</p>
        </React.Fragment>
    );
}