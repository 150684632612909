import React from "react";

export const Contact = () => {
    return (
        <React.Fragment>
            <h1>Contact</h1>
            <p>
                Wilt u meer informatie of wilt u een afspraak maken?<br />
                Ik ben telefonisch bereikbaar op maandag t/m vrijdag van 9.00 tot 20.00. Op andere dagen kunt u inspreken, u wordt dan teruggebeld.
            </p>
            <p>
                Tel: 06-53943501<br />
                Email: <a href="mailto:tessa@easyhomehairstyling.nl">tessa@easyhomehairstyling.nl</a>
            </p>
        </React.Fragment>
    );
}